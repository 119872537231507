
import { defineComponent, onMounted, onUnmounted } from "vue"
import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"

export default defineComponent({
  name: "auth",
  components: {},
  setup() {
    const store = useStore()

    onMounted(async () => {
      await store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body")
    })

    onUnmounted(async () => {
      await store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body")
    })

    return {}
  },
})
